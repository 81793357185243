//console.log(location.hostname);
import { ProgramProgramAcronym } from "./types";

type EmailDomain = "gmail.com" | "stanford.edu";
export const baseEmailDomain: EmailDomain = "stanford.edu";
//export const baseEmailDomain: EmailDomain = "gmail.com";

// impacts what explorecourses courses are available to use via magnifying glass
//export const defaultYear = "2023-2024";

// smartsheets -> firestore -> imported elsewhere in the project
// export const defaultYear = "2024-2025";
// export const defaultQuarter = "Fall"; // --> Autumn now...

export const hostname = location.hostname;
export const apiUrlFlask =
  hostname === "localhost" || hostname === "127.0.0.1"
    ? "http://localhost:8000/"
    : "/";
export const apiUrlEducAllCourses =
  //"https://thd1o8vbs7.execute-api.us-west-2.amazonaws.com/dev/course_total_subject_search/EDUC";
  //"https://ilearn-local-resources.s3.us-west-2.amazonaws.com/EDUC_all_courses.json";
  //"https://gseit-static-web-resources.s3.us-west-2.amazonaws.com/discovercourses/EDUC_all_courses.json.gz";
  //"https://storage.googleapis.com/gse-course-discovery.firebasestorage.app/course/EDUC_all_courses.json.gz";
  "https://storage.googleapis.com/gse-program-proposal.appspot.com/course/EDUC_all_courses.json.gz";

//export const apiUrlSingularCourseLookupPrefix =
//  "https://thd1o8vbs7.execute-api.us-west-2.amazonaws.com/dev/course/";

export const default215text = "Education Internship at Organization: ";
export const default180text = "Directed Reading in Education: ";
export const default190text = "Directed Research in Education: ";

export const fieldsBasicIntake = [
  {
    label: "Name",
    name: "name",
    placeholder: "Name",
    disabled: true,
  },
  //{
  //  label: "Phone",
  //  name: "phone",
  //  placeholder: "Phone",
  //  disabled: false,
  //},
  {
    label: "Student ID",
    name: "student_id",
    placeholder: "Student ID",
    disabled: true,
  },
  {
    label: "Student Email",
    name: "student_email",
    placeholder: "Student Email",
    disabled: true,
  },
  {
    label: "Director Email",
    name: "director_email",
    placeholder: "Director Email",
    disabled: true,
  },
  {
    label: "Advisor Email",
    name: "advisor_email",
    placeholder: "Advisor Email",
    disabled: true,
  },
];

export const mappingGradingOption = {
  "Letter or Credit/No Credit": ["Letter", "Credit/No Credit"],
  Letter: ["Letter", "Credit/No Credit"],
  "Credit/No Credit": ["Letter", "Credit/No Credit"],
  "Satisfactory/No Credit": ["S/NC"],
  Satisfactory: ["S/NC"],
  "No Credit": ["S/NC"],
  "S/NC": ["S/NC"],
  "Letter (ABCD/NP)": ["Letter", "Credit/No Credit"],
  "Letter (ABCD)": ["Letter (ABCD)"],
  NP: ["Letter (ABCD)"],

  "GSB Letter Graded": ["Letter", "Credit/No Credit"],
  "GSB Pass/Fail": ["S/NC"],
  "GSB Student Option LTR/PF": ["Letter", "Credit/No Credit"],

  "Law Honors/Pass/Restrd Cr/Fail": ["Letter (ABCD)"],
  "Law Mandatory P/R/F": ["S/NC"],
  "Law Mixed H/P/R/F or MP/R/F": ["Letter", "Credit/No Credit"],

  "Med-Ltr-CR/NC": ["Letter", "Credit/No Credit"],
  "Medical Option (Med-Ltr-CR/NC)": ["Letter", "Credit/No Credit"],
  "Medical Satisfactory/No Credit": ["S/NC"],
};

export const mappingProgramDirector = {
  pols: "ajaquith@stanford.edu",
  //pols: "wilsonw@stanford.edu",
  mpp: "ajaquith@stanford.edu",
  eds: "sannesmith@stanford.edu",
  //eds: "ssmith11@stanford.edu", change it to this value in the python backend
  ldt: "forssell@stanford.edu",
  hcp: "forssell@stanford.edu",
  joint_ma_mba: "gcox@stanford.edu",
  ice: "cwotipka@stanford.edu",
  iepa: "cwotipka@stanford.edu",
  //ice: "joshuawe@stanford.edu",
  //iepa: "joshuawe@stanford.edu",
  joint_law: "bkoski@stanford.edu",
  designed: "",
};

export const mappingProgramRequirement = {
  pols: [
    "Seminar",
    "Workshop",
    "Methods",
    "Elective",
    "Policy",
    "Organization",
  ],
  mpp: [
    "Seminar",
    "Workshop",
    "Methods",
    "Elective",
    "Policy",
    "Organization",
    "MPP course",
  ],
  eds: [
    "Core",
    "Statistics",
    "NLP",
    "Causal methods",
    "Network science",
    "Learning analytics",
    "Measurement",
    "Education Foundation",
    "Elective",
  ],
  ldt: [
    "Seminar",
    "Elective",
    "Internship",
    "Research Methods",
    "Design Process",
    "Learning Theories",
    "Learning Content",
    "Design of Learning Experiences",
    "Perspectives on Technology",
  ],
  hcp: [
    "Elective",
    "Internship",
    "Research Methods",
    "Design Process",
    "Learning Theories",
    "Learning Content",
    "Design of Learning Experiences",
    "Seminar",
    "Perspectives on Technology",
  ],
  joint_ma_mba: ["Skills", "Theory", "Knowledge", "Elective"],
  ice: [],
  iepa: [],
  designed: [],
};

export const projectBasename = "/proposal";

export const programProgramAcronym: ProgramProgramAcronym = {
  "Joint SoE/Law": "joint_law",
  "Curr Studies & Teacher Ed": "cte",
  "Individually Designed MA in Education": "designed",
  "Joint MA/MBA": "joint_ma_mba",
  "Learning, Design & Technology": "ldt",
  "Learning Design & Technology": "ldt",
  "Honors Cooperative Program": "hcp",
  "Policy/Organization/Leadership": "pols", //
  "Intl Comparative Educ": "ice", //
  "Intl Education Policy Analysis": "iepa", //
  "Joint Degree with the Public Policy Program": "mpp",
  "Education Data Science": "eds",
};

export type InverseProgramProgramAcronym = {
  [acronym: string]: string;
};

export const inverseProgramProgramAcronym: InverseProgramProgramAcronym = {};

// Populate the inverse mapping
for (const [program, acronym] of Object.entries(programProgramAcronym)) {
  inverseProgramProgramAcronym[acronym] = program;
}

export const timeInMinutesToCache = 60;

//https://ed.stanford.edu/academics/masters-handbook/program-requirements/eds
export const handbookUrlSuffixDictionary: Record<string, string> = {
  cte: "masters-handbook/program-requirements/cte",
  designed: "masters-handbook/program-requirements/ma-for-phd",
  ice: "masters-handbook/program-requirements/ice-ieapa",
  iepa: "masters-handbook/program-requirements/ice-ieapa",
  joint_law: "masters-handbook/program-requirements/joint-law",
  ldt: "masters-handbook/program-requirements/ldt",
  hcp: "masters-handbook/program-requirements/ldt",
  pols: "masters-handbook/program-requirements/pols",
  mpp: "masters-handbook/joint-degree-public-policy-program",
  eds: "masters-handbook/program-requirements/eds",
  joint_ma_mba: "masters-handbook/program-requirements/joint-gsb",
  //gsp: "doctoral-handbook",
};

export const quarterYearSequenceArray = [
  "Autumn 2020-2021",
  "Winter 2020-2021",
  "Spring 2020-2021",
  "Summer 2020-2021",

  "Autumn 2021-2022",
  "Winter 2021-2022",
  "Spring 2021-2022",
  "Summer 2021-2022",

  "Autumn 2022-2023",
  "Winter 2022-2023",
  "Spring 2022-2023",
  "Summer 2022-2023",

  "Autumn 2023-2024",
  "Winter 2023-2024",
  "Spring 2023-2024",
  "Summer 2023-2024",

  "Autumn 2024-2025",
  "Winter 2024-2025",
  "Spring 2024-2025",
  "Summer 2024-2025",

  "Autumn 2025-2026",
  "Winter 2025-2026",
  "Spring 2025-2026",
  "Summer 2025-2026",

  "Autumn 2026-2027",
  "Winter 2026-2027",
  "Spring 2026-2027",
  "Summer 2026-2027",

  "Autumn 2027-2028",
  "Winter 2027-2028",
  "Spring 2027-2028",
  "Summer 2027-2028",

  "Autumn 2028-2029",
  "Winter 2028-2029",
  "Spring 2028-2029",
  "Summer 2028-2029",

  "Autumn 2029-2030",
  "Winter 2029-2030",
  "Spring 2029-2030",
  "Summer 2029-2030",
];
