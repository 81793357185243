import React from "react";

export const TableHeader: React.FC<{ program: string }> = ({ program }) => {
  switch (program) {
    case "designed":
    case "ice":
    case "iepa":
    case "joint_ma_mba":
      return (
        <div className="flex flex-wrap items-center mt-10 mb-6 font-bold">
          <div className="w-2/12 pl-4">Course #</div>
          <div className="w-6/12 pl-2">Course Title</div>
          <div className="w-2/12" title="Grading option">
            Grading
          </div>
          <div className="w-2/12">Units</div>
        </div>
      );
    default:
      return (
        <div className="flex flex-wrap items-center mt-10 mb-6 font-bold">
          <div className="w-2/12 pl-4">Course #</div>
          <div className="w-5/12 pl-2">Course Title</div>
          <div className="w-1/12" title="Grading option">
            Grading
          </div>
          <div className="w-2/12" title="Course requirement">
            Requirement
          </div>
          <div className="w-2/12">Units</div>
        </div>
      );
  }
};

export default TableHeader;
