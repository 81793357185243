// import { useEffect } from "react";
//import { useState, useEffect } from "react";
import { useContext, useState } from "react";
import { Link } from "react-router";

import AboutSiteGrid from "../components/AboutSiteGrid";
import FooterStanford from "../components/FooterStanford";
import HeaderStanford from "../components/HeaderStanford";
import SiteGridExample from "../components/SiteGridExample";

import { getConstantValues, handleSignIn } from "../extras/auxiliary_functions";

import { functions } from "../../firebase.config.js";
import { SessionContext } from "../components/SessionContext";
import { httpsCallable } from "firebase/functions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const handleButtonClick = async () => {
    const stuff = await getConstantValues("defaultYear");
    console.log(stuff);

    const fetchData = httpsCallable(functions, "firebase_constant_values_sync");
    fetchData({ text: "testing!" }).then((result) => {
      const data = result.data;
      console.log(data);
    });
  };
  const handleButtonClick2 = async () => {
    const fetchData = httpsCallable(
      functions,
      "firebase_round_configuration_sync",
    );
    fetchData({ text: "testing!" }).then((result) => {
      const data = result.data;
      console.log(data);
    });
  };

  const handleButtonClick3 = async () => {
    const fetchData = httpsCallable(functions, "firebase_form_submission_sync");
    fetchData({ text: "testing!" }).then((result) => {
      const data = result.data;
      console.log(data);
    });
  };

  const handleButtonClick5 = async () => {
    const fetchData = httpsCallable(
      functions,
      "firebase_smartsheet_override_program_last_quarter_sync",
    );
    fetchData({ text: "testing!" }).then((result) => {
      const data = result.data;
      console.log(data);
    });
  };

  const handleButtonClick6 = async () => {
    // const fetchData = httpsCallable(
    //   functions,
    //   "firebase_smartsheet_override_program_last_quarter_sync",
    // );
    // fetchData({ text: "testing!" }).then((result) => {
    //   const data = result.data;
    //   console.log(data);
    // });

    console.log(
      "The text does not start with 'EDUC' or is a different academic year",
    );
    const yearMMMMmmmm = "2024-2025";
    const transformedCourseNumber = "CS124";
    const fetchData = httpsCallable(functions, "firebase_course_direct");
    console.log(" ");
    console.log(yearMMMMmmmm);
    console.log(transformedCourseNumber);
    const result = await fetchData({
      course_code: transformedCourseNumber,
      academicYear: yearMMMMmmmm,
    });
    const newdata = result.data as any;
    const data = newdata[0];
    console.log("data");
    console.log(data);
  };

  const { userData } = useContext(SessionContext);

  const [inputValue, setInputValue] = useState(5);

  const handleIncrement = () => {
    setInputValue(inputValue + 1);
  };

  const handleDecrement = () => {
    setInputValue(inputValue - 1);
  };

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };
  const allowedEmails = [
    "jonlai@stanford.edu",
    "wilsonw@stanford.edu",
    "stwong80@stanford.edu",
  ];

  return (
    <div>
      <HeaderStanford />
      {allowedEmails.includes(userData.email) ? (
        <>
          <div>
            {userData.displayName && (
              <p>Display Name: {userData.displayName}</p>
            )}
            {userData.email && <p>Email: {userData.email}</p>}
            {userData.username && <p>Username: {userData.username}</p>}
          </div>
          <div>
            <button className="button" onClick={handleButtonClick}>
              Test handleButtonClick, constant values
            </button>
          </div>
          <p>hello</p>
          <div>
            <button className="button" onClick={handleButtonClick2}>
              Test handleButtonClick2, firebase round configuration sync
            </button>
          </div>
          <p>bye</p>
          <div>
            <button className="button" onClick={handleButtonClick3}>
              Test handleButtonClick3, firebase form submission sync
            </button>
          </div>
          <p>hello</p>
          <div>
            <button className="button" onClick={handleButtonClick5}>
              Test handleButtonClick5,
              firebase_smartsheet_override_program_last_quarter_sync
            </button>
          </div>
          <p>bonjour</p>
          <div>
            <button className="button" onClick={handleButtonClick6}>
              Test handleButtonClick6, firebase_course_direct
            </button>
          </div>
          <p>nihao</p>

          <div className="">
            <div className="relative w-180">
              <button
                type="button"
                className="absolute left-0 top-0 rounded-r-none btn btn-square px-10 pt-8 border-r-2"
                onClick={handleDecrement}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <input
                type="text"
                className="w-full text-center input"
                value={inputValue}
                onChange={handleChange}
              />
              <button
                type="button"
                className="absolute right-0 top-0 rounded-l-none btn btn-square px-10 pt-8 border-l-2"
                onClick={handleIncrement}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>

          <AboutSiteGrid />
          <div className="cc flex flex-col md:flex-row rs-py-2 overflow-x-hidden">
            <div className="mx-auto w-full">
              <article id="text__paragraphs" className="rs-mb-5">
                <p>
                  There are four main pages of this site, and of course this
                  unlisted About page too
                </p>
                <ul>
                  <li>
                    <Link to="/">Home</Link>{" "}
                  </li>
                  <li>
                    <Link to="/form">Form</Link>{" "}
                  </li>
                  <li>
                    <Link to="/status">Status</Link>{" "}
                  </li>
                  <li>
                    <Link to="/login">Login</Link>{" "}
                  </li>
                  <li>
                    <Link to="/about">About</Link>{" "}
                  </li>
                </ul>
              </article>
            </div>
          </div>
          <SiteGridExample />
        </>
      ) : (
        <div>
          <p className="text-4xl">
            {userData.email ? `Hello ${userData.email}` : ""}
          </p>

          <p>
            <button
              onClick={() => handleSignIn()}
              className="button"
              type="button"
            >
              Stanford Login
            </button>
          </p>
        </div>
      )}
      <FooterStanford />
    </div>
  );
};

export default About;
