import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import { Formik, FormikProps, Form, Field, FieldArray } from "formik";
import { pick, isEqual } from "lodash";
import FocusLock from "react-focus-lock";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { functions } from "../../firebase.config.js";
import { SessionContext } from "../components/SessionContext";
import { httpsCallable } from "firebase/functions";

import {
  AlertErrorBasic,
  AlertErrorValidations,
  AlertInfoValidations,
} from "../components/AlertItems";
import Attestation from "../components/Attestation";
import BeyondCourseCatalogNotice from "../components/BeyondCourseCatalogNotice";
import FooterStanford from "../components/FooterStanford";
import GridWithCheckBoxes from "../components/GridWithCheckBoxes";
import HeaderStanford from "../components/HeaderStanford";
import NavigateToStatusPage from "../components/NavigateToStatusPage";
import PlanAPlanBCheckboxes from "../components/PlanAPlanBCheckboxes";
//import SmartsheetPresentStatus from "../components/SmartsheetPresentStatus";
import SpinnerOverlay from "../components/SpinnerOverlay";
import ToastConfig from "../components/ToastConfig";
import { TableHeader } from "../components/TableHeader";
import {
  //RowInputCheckIfCrossListed,
  RowInputCourseNumber,
  RowInputCourseTitle,
  RowInputGradingOption,
  RowInputProgramRequirement,
  RowInputRemoveButton,
  RowInputSingularUnits,
  RowInputStandard,
} from "../components/RowInput";
import { SubmitBasic, SubmitPDF } from "../components/SubmitButtons";
import {
  AddCourse,
  AllTotalUnits,
  QuarterTotalUnits,
  //YearNumberTotalUnits,
} from "../components/TotalUnits";
import { UniqueScheduleModal, PdfModal } from "../components/CustomModals";

import {
  baseEmailDomain,
  mappingProgramRequirement,
  programProgramAcronym,
  quarterYearSequenceArray,
} from "../extras/constant_values";

import {
  legacyEmailAddressesHaveRows,
  //hasEmail,
  getEmailData,
} from "../extras/legacyImportTwoYearAccounts";

import {
  checkMetaRules,
  determineFormVisibility,
  emptyRowGenerator,
  excludeCourseUnits,
  fetchOverrides,
  fetchUserdataPersonalAndInitialValues,
  //generateYearQuarterRows,
  getConstantValues,
  getLatestSubmission,
  getProgramVanityName,
  handleSignIn,
  //initializeMeta,
  programAcronymFromDataFullProgram,
  //programDifferentiatorHasCrossListed,
  programDifferentiatorHasPlanAPlanB,
  //programDifferentiatorHasProgramRequirement,
  programDifferentiatorNoAdvisor,
  programDifferentiatorNoDirector,
  removeQuarter,
  updateInitialValues,
} from "../extras/auxiliary_functions";
import { GenericError } from "../extras/errors";
import { YearQuarterRow } from "../extras/interfaces";
import { ProgramAcronym, UserdataPersonalType } from "../extras/types";

import { validationSchemaSetup } from "../extras/validations";

const IntakeForm = () => {
  const [messageListAlertErrors, setMessageListAlertErrors] = useState<
    string[]
  >([]);
  const [messageListAlertInfo, setMessageListAlertInfo] = useState<string[]>(
    [],
  );

  const handleValidationErrorMessages = (messagesError: string[]) => {
    setMessageListAlertErrors(messagesError);
  };
  const handleValidationInfoMessages = (messagesInfo: string[]) => {
    setMessageListAlertInfo(messagesInfo);
  };

  const { userData } = useContext(SessionContext);

  const [pageLoading, setPageLoading] = useState(true);
  //const formRef = useRef();
  const formRef = useRef<FormikProps<any> | null>(null);

  // https://react.dev/reference/react-dom/components/input#controlling-an-input-with-a-state-variable
  const [userdataPersonal, setUserdataPersonal] =
    useState<UserdataPersonalType | null>(null);
  const [formErrors, setFormErrors] = useState({});
  // const [studentid, setStudentid] = useState("");
  const [programRequirement, setProgramRequirement] = useState<string[]>([]);
  const [totalSum, setTotalSum] = useState(0);
  const prevRowsRef = useRef<any[] | null>(null);
  const [formikKey, setFormikKey] = useState(0);
  const [isModalOpenUnique, setModalOpenUnique] = useState<boolean>(false);
  const [isModalOpenPdf, setModalOpenPdf] = useState<boolean>(false);
  const [loadingSmartsheet, setLoadingSmartsheet] = useState(false);
  const [urlPdfFileStorage, setUrlPdfFileStorage] = useState("");
  const [pdfFileName, setPdfFileName] = useState("");
  const [shouldPageRefreshContent, setShouldPageRefreshContent] =
    useState<boolean>(false);

  const [isFormIntakeVisible, setFormIntakeVisible] = useState<boolean>(false);
  const [pageStatusMessage, setPageStatusMessage] = useState<string>("");
  const [constantValues, setConstantValues] = useState<{
    defaultYear: string | null;
    defaultQuarter: string | null;
    courseAccessLastQuarterYear: string | null;
  } | null>(null);
  const [
    smartsheetOverridesProgramLastQuarterRelevantIndividual,
    setSmartsheetOverridesProgramLastQuarterRelevantIndividual,
  ] = useState<{
    program: string | null;
    override_last_quarter_units: boolean | null;
  } | null>(null);

  const openModalUnique = () => {
    setModalOpenUnique(true);
  };

  const closeModalUnique = () => {
    setModalOpenUnique(false);
  };
  const openModalPdf = () => {
    setModalOpenPdf(true);
  };

  const closeModalPdf = () => {
    setModalOpenPdf(false);
  };

  const [initialValues, setInitialValues] = useState({
    name: "",
    //phone: "",
    student_id: "",
    student_email: "",
    advisor_email: "",
    director_email: "",
    program: "",
    //rows: all_rows,
    rows: [],
    checkboxes: {},
    total_units: 0,
    submission_round: "",
    attestation: "",
  });

  useEffect(() => {
    const fetchConstantValues = async () => {
      try {
        const defaultYear = await getConstantValues("defaultYear");
        const defaultQuarter = await getConstantValues("defaultQuarter");
        const courseAccessLastQuarterYear = await getConstantValues(
          "courseAccessLastQuarterYear",
        );
        // help me set a value here for constant values
        setConstantValues({
          defaultYear,
          defaultQuarter,
          courseAccessLastQuarterYear,
        });
      } catch (error) {
        console.error("Error fetching constant values:", error);
      }
    };

    fetchConstantValues();
  }, []);

  useEffect(() => {
    console.log("lets fetch extra data for the user");
    fetchUserdataPersonalAndInitialValues(
      setUserdataPersonal,
      setInitialValues,
      userData,
    );
  }, [userData, shouldPageRefreshContent]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if userdataPersonal has been fetched and is available
        if (userdataPersonal) {
          const studentEmail = `${userdataPersonal.uid}@${baseEmailDomain}`;
          await fetchOverrides(
            studentEmail,
            setSmartsheetOverridesProgramLastQuarterRelevantIndividual,
          );

          const programAcronym =
            programAcronymFromDataFullProgram(userdataPersonal);

          const programRequirementSelectArray =
            mappingProgramRequirement[programAcronym as ProgramAcronym];
          setProgramRequirement(programRequirementSelectArray);
          const year_quarter_setup = userdataPersonal.year_quarter_setup;
          const year_quarter_setup_original =
            userdataPersonal.year_quarter_setup_original;
          const is_unique_schedule = userdataPersonal.is_unique_schedule;
          const all_rows: YearQuarterRow[] = year_quarter_setup.map(
            (entry: any) => {
              const coursesObject = emptyRowGenerator(
                entry.year,
                entry.quarter,
                1,
                programAcronym,
              );
              return {
                year: entry.year,
                quarter: entry.quarter,
                courses: coursesObject.courses,
                total: 0,
              };
            },
          );

          if (studentEmail) {
            const submissionDict = await getLatestSubmission(studentEmail);
            if (!submissionDict) {
              setFormIntakeVisible(true);
              updateInitialValues(
                setInitialValues,
                setFormikKey,
                all_rows,
                year_quarter_setup,
                year_quarter_setup_original,
                is_unique_schedule,
              );
              console.log(" new initialValues");
              console.log(initialValues);

              if (legacyEmailAddressesHaveRows.includes(studentEmail)) {
                console.log("doing a legacy two year program course lookup");
                console.log("doing a legacy two year program course lookup");
                console.log(studentEmail);

                const goodrows = getEmailData(studentEmail);
                const tempInitialValues: any = {
                  ...initialValues,
                  year_quarter_setup: year_quarter_setup,
                  year_quarter_setup_original: year_quarter_setup_original,
                  is_unique_schedule: is_unique_schedule,
                  rows: goodrows,
                };
                setInitialValues(tempInitialValues);
              }

              setTimeout(() => {
                setPageLoading(false);
              }, 300);
              throw new Error(
                "No previous proposal saved. Initialize proposal account please.",
              );
            }
            const fetchedDataStudent = submissionDict as any;
            const savedProgram = fetchedDataStudent.program as string;

            const id_form_submission_lookup =
              fetchedDataStudent.id_form_submission as string;

            const mensaje = await determineFormVisibility(
              studentEmail,
              id_form_submission_lookup,
              setFormIntakeVisible,
            );
            if (mensaje === "do not show form") {
              console.log("Redirecting to /status");
              setPageStatusMessage(mensaje);
            }

            // repopulate form conditionally 1) there is old data AND 2) the program is the same 3) it is not a unique schedule
            const addOns = {
              year_quarter_setup: year_quarter_setup,
              year_quarter_setup_original: year_quarter_setup_original,
              is_unique_schedule: is_unique_schedule,
            };
            const totalSetupData = {
              ...fetchedDataStudent,
              ...addOns,
            };
            const current_quarter_setup_as_defined_by_rows =
              totalSetupData.rows.map((quarterData: any) => ({
                quarter: quarterData.quarter,
                year: quarterData.year,
              }));

            const hasFetchedData = Object.keys(fetchedDataStudent).length > 0;
            const isSameProgram = savedProgram === programAcronym;
            const isIepaIceProgramSwitch =
              (savedProgram === "iepa" && programAcronym === "ice") ||
              (savedProgram === "ice" && programAcronym === "iepa");

            const isQuarterSetupEqual = isEqual(
              current_quarter_setup_as_defined_by_rows,
              year_quarter_setup,
            );

            if (hasFetchedData && isSameProgram) {
              if (isQuarterSetupEqual) {
                setInitialValues(totalSetupData);
              } else {
                // unique schedule situation, the number of quarters changes,
                // preserve old rows data and adjust accordingly
                const oldRowsFull = totalSetupData.rows;
                const newRowsFull = year_quarter_setup.map((setup: any) => {
                  const foundItem = oldRowsFull.find(
                    (item: any) =>
                      item.quarter === setup.quarter &&
                      item.year === setup.year,
                  );

                  return (
                    foundItem || {
                      quarter: setup.quarter,
                      courses: emptyRowGenerator(
                        setup.year,
                        setup.quarter,
                        1,
                        programAcronym,
                      ).courses,
                      total: 0,
                      year: setup.year,
                    }
                  );
                });

                setInitialValues({
                  ...totalSetupData,
                  rows: newRowsFull,
                });
              }
            } else if (hasFetchedData && isIepaIceProgramSwitch) {
              setInitialValues({
                ...totalSetupData,
                program: programAcronym,
              });
            } else {
              updateInitialValues(
                setInitialValues,
                setFormikKey,
                all_rows,
                year_quarter_setup,
                year_quarter_setup_original,
                is_unique_schedule,
              ); // reinitialize the firestore row entry with new information
            }

            setTimeout(() => {
              setPageLoading(false);
            }, 300);
          }
        }
      } catch (error: any) {
        console.error(
          "There was a problem with the fetch operation:",
          error.message,
        );
      }
    };

    fetchData(); // Invoke the async function
  }, [userdataPersonal]);

  // Define a new state to keep track of the changes in initialValues
  //const [initialValuesLog, setInitialValuesLog] = useState(all_rows);
  const [initialValuesLog, setInitialValuesLog] = useState({
    ...initialValues,
    //rows: all_rows,
    rows: [],
  });

  // useEffect to monitor changes in initialValues and log them to the console
  //useEffect(() => {
  //  //console.log("Initial Values Changed:", initialValuesLog);
  //}, [initialValuesLog]);
  useEffect(() => {
    if (userdataPersonal) {
      console.log("Initial Values Changed:", initialValuesLog);
    }
  }, [userdataPersonal, initialValuesLog]);

  return (
    <div className="flex flex-col w-full min-h-screen">
      <ToastConfig />
      <HeaderStanford />
      <div className="cc rs-pb-2 overflow-x-hidden w-full grow">
        <div>
          {userData.username ? (
            <article id="text__paragraphs" className="rs-mb-5">
              <div className="text-white">
                User Details: {userdataPersonal?.uid},{" "}
                {userdataPersonal?.displayName},{" "}
                {userdataPersonal?.eduPersonPrincipalName},{" "}
                {userdataPersonal?.suAffiliation}, {userdataPersonal?.program},{" "}
                {userdataPersonal?.program
                  ? programProgramAcronym[userdataPersonal?.program]
                  : ""}
              </div>

              {/* https://stackoverflow.com/questions/53920132/how-to-pass-state-values-to-initial-values-in-formik-in-react-js */}
              {isFormIntakeVisible && (
                <Formik
                  innerRef={formRef}
                  key={formikKey} // Use the formikKey state variable here
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchemaSetup(
                    programProgramAcronym[userdataPersonal?.program as string],
                  )}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      // Simulate asynchronous operation
                      //await new Promise((resolve) => setTimeout(resolve, 200));
                      //console.log(JSON.stringify(values, null, 2));
                      //setSubmitting(false);
                      // repurposing the checkmarks value to store the textfield of the rule, instead of true false being checked off
                      //const newmeta = initializeMeta(values);
                      //values["checkboxes"] = newmeta;
                      const keysToRetain = [
                        "name",
                        //"phone",
                        "student_id",
                        "student_email",
                        "advisor_email",
                        "director_email",
                        "program",
                        "rows",
                        "total_units",
                        "checkboxes",
                        "plan",
                        "submission_round",
                        "attestation",
                        "id_form_submission",
                      ];
                      const newValues = pick(values, keysToRetain);
                      const fetchData = httpsCallable(
                        functions,
                        //"firebase_save_form_data",
                        "firebase_save_form_data_firestore",
                      );
                      const result = await fetchData(newValues);
                      const data = result.data as any;
                      // Reload id_form_submission values, the form just updated to a newer row in firestore
                      const id_form_submission = data.id_form_submission;
                      const submission_round = data.submission_round;
                      if (data.extra === "reload") {
                        setInitialValues((prevInitialValues: any) => ({
                          ...prevInitialValues,
                          ...newValues,
                          id_form_submission: id_form_submission,
                          submission_round: submission_round,
                        }));
                      }
                      setSubmitting(false);
                      return { id_form_submission: id_form_submission };
                    } catch (error: any) {
                      console.error(
                        "There was a problem with the fetch operation:",
                        error.message,
                      );
                      setSubmitting(false);
                      throw error; // Throw the error to be caught by the parent component
                    }
                  }}
                >
                  {/*({ isSubmitting, values, errors, touched, submitCount, setFieldValue }) => ( */}
                  {({
                    isSubmitting,
                    values,
                    errors,
                    submitCount,
                    setFieldValue,
                  }) => {
                    // Update the local state whenever Formik's error object changes
                    useEffect(() => {
                      setFormErrors(errors);
                    }, [errors]);

                    // Manipulate the alertDivBasic based on the errors
                    useEffect(() => {
                      const alertDivBasicClassList =
                        document.getElementById("alertDivBasic")?.classList;
                      //console.log(formErrors);
                      //console.log(alertDivBasicClassList);
                      const shouldShowAlert =
                        Object.keys(formErrors).length > 0 && submitCount > 0;
                      const targetClass = shouldShowAlert ? "flex" : "hidden";
                      const removeClass = shouldShowAlert ? "hidden" : "flex";
                      if (alertDivBasicClassList?.contains(removeClass)) {
                        alertDivBasicClassList.remove(removeClass);
                        alertDivBasicClassList.add(targetClass);
                      }
                    }, [formErrors, submitCount]);

                    const initialValuesRef = useRef(initialValues);
                    useEffect(() => {
                      // Calculate newValuesRows based on current values.rows
                      var newValuesRows = values.rows?.map((quarter: any) => ({
                        ...quarter,
                        total: quarter.courses.reduce(
                          (acc: number, course: any) => {
                            if (!course.courseNumber) {
                              return acc;
                            } else if (
                              excludeCourseUnits(course.courseNumber)
                            ) {
                              return acc;
                            } else {
                              return acc + parseInt(course.courseUnits, 10);
                            }
                          },
                          0,
                        ),
                      }));

                      // Calculate the total sum of quarter units
                      const newTotalSum = newValuesRows?.reduce(
                        (acc: number, quarter: any) => acc + quarter.total,
                        0,
                      );

                      // Check if newValuesRows is different from prevRows
                      const isNewValuesRowsDifferent =
                        JSON.stringify(newValuesRows) !==
                        JSON.stringify(prevRowsRef.current);

                      // Check if any values in the form have changed
                      const isFormChanged =
                        JSON.stringify(values) !==
                        JSON.stringify(initialValuesRef.current);

                      if (isNewValuesRowsDifferent || isFormChanged) {
                        // Update the ref with the new rows
                        prevRowsRef.current = newValuesRows;

                        // Update values.rows only if different
                        setFieldValue("rows", newValuesRows);
                        setFieldValue("total_units", newTotalSum);

                        // Update the initialValuesRef with the new form values
                        initialValuesRef.current = {
                          ...values,
                          rows: newValuesRows as YearQuarterRow[],
                        } as typeof values;

                        // Update the total sum state
                        setTotalSum(newTotalSum);

                        if (values.year_quarter_setup) {
                          const metaRulesObject = checkMetaRules(values);
                          // it will be undefined on initial page load
                          if (
                            typeof metaRulesObject !== "undefined" &&
                            Object.keys(metaRulesObject).length > 0
                          ) {
                            const tempcheckboxes =
                              metaRulesObject.tempcheckboxes;
                            let original_checkbox = values.checkboxes;
                            // Iterate over each checkbox in original_checkbox
                            for (const checkboxId in original_checkbox) {
                              if (tempcheckboxes[checkboxId]) {
                                // If the checkbox exists in tempcheckboxes, update its checked value
                                original_checkbox[checkboxId].checked =
                                  tempcheckboxes[checkboxId].checked;
                              }
                            }
                          }
                        }

                        // Update initialValuesLog to log changes
                        //setInitialValuesLog({ ...values, rows: newValuesRows });
                        //setInitialValuesLog({ ...values, rows: newValuesRows } as {
                        //  rows: YearQuarterRow[];
                        //});

                        //setInitialValuesLog({
                        //  ...values,
                        //  rows: newValuesRows as YearQuarterRow[], // Ensure the correct type
                        //});

                        setInitialValuesLog({
                          ...values,
                          rows: newValuesRows as YearQuarterRow[],
                        } as typeof values); // Explicitly specify the type
                      }
                    }, [values, setFieldValue]);

                    return (
                      <React.Fragment>
                        <Form>
                          <div>
                            <h2 className="type-2 mb-0">
                              Student Information{" "}
                              {userdataPersonal?.program
                                ? `for ${getProgramVanityName(
                                    programProgramAcronym[
                                      userdataPersonal?.program
                                    ],
                                  )}`
                                : ""}
                            </h2>
                          </div>
                          <div className="md:grid md:grid-cols-2 md:gap-4">
                            <div className="col-start-1">
                              <label className="my-5 pt-5 inline-flex items-center font-bold">
                                Name
                              </label>
                              <Field
                                name="name"
                                type="text"
                                placeholder="Name"
                                className="input w-8/12 border-none p-0"
                                style={{
                                  padding: "0",
                                }}
                                disabled={true}
                              />
                              <GenericError
                                errors={errors}
                                submitCount={submitCount}
                                fieldName="name"
                              />
                            </div>

                            <div className="col-start-2">
                              <label className="my-5 pt-5 inline-flex items-center font-bold">
                                Student ID
                              </label>
                              <Field
                                name="student_id"
                                type="text"
                                placeholder="Student ID"
                                className="input w-8/12 border-none p-0"
                                style={{
                                  padding: "0",
                                }}
                                disabled={true}
                              />
                              <GenericError
                                errors={errors}
                                submitCount={submitCount}
                                fieldName="student_id"
                              />
                            </div>

                            <div className="col-start-1">
                              <label className="my-5 pt-5 inline-flex items-center font-bold">
                                Student Email
                              </label>
                              <Field
                                name="student_email"
                                type="text"
                                placeholder="Student Email"
                                className="input w-8/12 border-none p-0"
                                style={{
                                  padding: "0",
                                }}
                                disabled={true}
                              />
                              <GenericError
                                errors={errors}
                                submitCount={submitCount}
                                fieldName="student_email"
                              />
                            </div>

                            {/* Director Email Field */}
                            <div
                              className="col-start-2"
                              style={{
                                display: programDifferentiatorNoDirector(
                                  values.program,
                                )
                                  ? "none"
                                  : "block",
                              }}
                            >
                              <label className="my-5 pt-5 inline-flex items-center font-bold">
                                Director Email
                              </label>
                              {programDifferentiatorNoDirector(
                                values.program,
                              ) ? (
                                <Field
                                  name="director_email"
                                  type="text"
                                  placeholder="Director Email"
                                  className="input w-8/12"
                                  disabled={true}
                                  value=""
                                />
                              ) : (
                                <>
                                  <Field
                                    name="director_email"
                                    type="text"
                                    placeholder="Director Email"
                                    className="input w-8/12 border-none p-0"
                                    style={{
                                      padding: "0",
                                    }}
                                    disabled={true}
                                  />
                                  <GenericError
                                    errors={errors}
                                    submitCount={submitCount}
                                    fieldName="director_email"
                                  />
                                </>
                              )}
                            </div>

                            {/* Advisor Email Field */}
                            <div
                              className="col-start-1"
                              style={{
                                display: programDifferentiatorNoAdvisor(
                                  values.program,
                                )
                                  ? "none"
                                  : "block",
                              }}
                            >
                              <label className="my-5 pt-5 inline-flex items-center font-bold">
                                Advisor Email
                              </label>

                              {programDifferentiatorNoAdvisor(
                                values.program,
                              ) ? (
                                <Field
                                  name="advisor_email"
                                  type="text"
                                  placeholder="Advisor Email"
                                  className="input w-8/12"
                                  disabled={true}
                                  value=""
                                />
                              ) : (
                                // Check if there's one or multiple advisors
                                <>
                                  {values.advisor_email.includes(",") ? (
                                    // If multiple advisors, show select dropdown

                                    <>
                                      {/* If multiple advisors, show select dropdown */}
                                      <Field
                                        as="select"
                                        name="advisor_email"
                                        className="input w-8/12"
                                        disabled={false}
                                      >
                                        {/* Empty placeholder option */}
                                        <option value="">
                                          Select advisor for approval
                                        </option>
                                        {values.advisor_email
                                          .split(",")
                                          .map(
                                            (
                                              advisor: string,
                                              index: number,
                                            ) => (
                                              <option
                                                key={index}
                                                value={advisor.trim()}
                                              >
                                                {advisor.trim()}
                                              </option>
                                            ),
                                          )}
                                      </Field>

                                      {/* Information text */}
                                      <p className="text-2xl text-red-500 mt-2">
                                        Select your advisor, your profile in the
                                        system lists multiple advisors.
                                      </p>
                                    </>
                                  ) : (
                                    // If one advisor, show text input
                                    <Field
                                      name="advisor_email"
                                      type="text"
                                      placeholder="Advisor Email"
                                      className="input w-8/12 border-none p-0"
                                      style={{ padding: "0" }}
                                      disabled={true}
                                    />
                                  )}
                                  <GenericError
                                    errors={errors}
                                    submitCount={submitCount}
                                    fieldName="advisor_email"
                                  />
                                </>
                              )}
                            </div>
                          </div>

                          <div className="mt-40">
                            {programDifferentiatorHasPlanAPlanB(
                              values.program,
                            ) ? (
                              <h2 className="type-2 mb-0">
                                Schedule and Plan Selection
                              </h2>
                            ) : (
                              <h2 className="type-2 mb-0">Schedule</h2>
                            )}
                          </div>

                          <div>
                            If you are a coterm student and or have a leave of
                            absence, you can{" "}
                            <a
                              onClick={openModalUnique}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  openModalUnique();
                                }
                              }}
                              className="button-link"
                              role="button"
                              tabIndex={0}
                            >
                              change your schedule here
                            </a>
                          </div>

                          <div className="mt-10">
                            {isModalOpenPdf && (
                              <FocusLock>
                                <PdfModal
                                  values={values}
                                  isOpen={isModalOpenPdf}
                                  onClose={closeModalPdf}
                                  urlPdfFileStorage={urlPdfFileStorage}
                                  pdfFileName={pdfFileName}
                                  setFormIntakeVisible={setFormIntakeVisible}
                                  setLoadingSmartsheet={setLoadingSmartsheet}
                                />
                              </FocusLock>
                            )}

                            {isModalOpenUnique && (
                              <FocusLock>
                                <UniqueScheduleModal
                                  student_email={values.student_email}
                                  isOpen={isModalOpenUnique}
                                  onClose={closeModalUnique}
                                  program={values.program}
                                  constantValues={constantValues}
                                  setShouldPageRefreshContent={
                                    setShouldPageRefreshContent
                                  }
                                  year_quarter_setup={values.year_quarter_setup}
                                  year_quarter_setup_original={
                                    values.year_quarter_setup_original
                                  }
                                />
                              </FocusLock>
                            )}
                          </div>
                          {programDifferentiatorHasPlanAPlanB(
                            values.program,
                          ) && (
                            <div className="flex flex-col w-8/12 my-10">
                              <PlanAPlanBCheckboxes
                                errors={errors}
                                submitCount={submitCount}
                              />
                            </div>
                          )}
                          {/*<TableHeader program={values.program} / > */}
                          <FieldArray name="rows">
                            {() => {
                              // {({ remove, push, insert, replace, swap, move }) => {

                              const courseAccessLastQuarterYear = constantValues
                                ? (constantValues[
                                    "courseAccessLastQuarterYear"
                                  ] as string)
                                : ("" as string);
                              const courseAccessLastQuarterYearIndex =
                                quarterYearSequenceArray.indexOf(
                                  courseAccessLastQuarterYear,
                                );

                              return (
                                <div>
                                  {values.rows?.map(
                                    (row: YearQuarterRow, rowIndex: number) => {
                                      const rowyear = row["year"];
                                      const rowquarter = row["quarter"];

                                      const currentQuarter = `${rowquarter} ${rowyear}`;
                                      const currentQuarterIndex =
                                        quarterYearSequenceArray.indexOf(
                                          currentQuarter,
                                        );

                                      return (
                                        <div
                                          key={`block_${rowIndex}`}
                                          className="mt-14"
                                        >
                                          {currentQuarterIndex ===
                                            courseAccessLastQuarterYearIndex +
                                              1 && (
                                            <BeyondCourseCatalogNotice />
                                          )}

                                          <div className="year-quarter bg-stone-500 text-white">
                                            <h3 className="type-1 py-5 px-10 mb-0 flex items-center">
                                              {rowyear} {rowquarter}
                                              {!values.year_quarter_setup_original.some(
                                                (item: any) =>
                                                  item.quarter === rowquarter &&
                                                  item.year === rowyear,
                                              ) && (
                                                <span className="flex flex-grow justify-end items-center pr-8">
                                                  <button
                                                    className="remove my-4 ml-6 text-white-500"
                                                    type="button"
                                                    onClick={() => {
                                                      removeQuarter(
                                                        rowyear,
                                                        rowquarter,
                                                        values,
                                                        //setFieldValue,
                                                        //setInitialValuesLog,
                                                      );
                                                      setShouldPageRefreshContent(
                                                        true,
                                                      );
                                                      setTimeout(() => {
                                                        setShouldPageRefreshContent(
                                                          false,
                                                        );
                                                      }, 300);
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faTrashCan}
                                                    />
                                                  </button>
                                                </span>
                                              )}
                                            </h3>
                                          </div>

                                          <div className="course-table border-2 border-stone-400 px-10">
                                            <TableHeader
                                              program={values.program}
                                            />
                                            {row.courses.map(
                                              (
                                                course: any,
                                                courseIndex: number,
                                              ) => {
                                                return (
                                                  <div
                                                    key={`${rowIndex}_${courseIndex}`}
                                                  >
                                                    <div className="flex flex-wrap items-center mt-2 border-t pt-2 border-stone-400 pt-6 pb-4">
                                                      <div className="flex flex-col hidden">
                                                        <RowInputStandard
                                                          rowIndex={rowIndex}
                                                          row={row}
                                                          name="quarter"
                                                        />
                                                      </div>

                                                      <div className="flex flex-col hidden">
                                                        <RowInputStandard
                                                          rowIndex={rowIndex}
                                                          row={row}
                                                          name="year"
                                                        />
                                                      </div>

                                                      <div className="pl-4 flex flex-col w-2/12 iconHere">
                                                        <RowInputCourseNumber
                                                          courseIndex={
                                                            courseIndex
                                                          }
                                                          errors={errors}
                                                          initialValues={
                                                            initialValues
                                                          }
                                                          rowIndex={rowIndex}
                                                          setFieldValue={
                                                            setFieldValue
                                                          }
                                                          setProgramRequirement={
                                                            setProgramRequirement
                                                          }
                                                          submitCount={
                                                            submitCount
                                                          }
                                                          values={values}
                                                          constantValues={
                                                            constantValues
                                                          }
                                                          currentQuarterIndex={
                                                            currentQuarterIndex
                                                          }
                                                          courseAccessLastQuarterYearIndex={
                                                            courseAccessLastQuarterYearIndex
                                                          }
                                                        />
                                                      </div>

                                                      {(() => {
                                                        switch (
                                                          values.program
                                                        ) {
                                                          case "designed":
                                                          case "ice":
                                                          case "iepa":
                                                          case "joint_ma_mba":
                                                            return (
                                                              <>
                                                                <div className="flex flex-col w-6/12">
                                                                  <RowInputCourseTitle
                                                                    courseIndex={
                                                                      courseIndex
                                                                    }
                                                                    errors={
                                                                      errors
                                                                    }
                                                                    rowIndex={
                                                                      rowIndex
                                                                    }
                                                                    submitCount={
                                                                      submitCount
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="pr-10 flex flex-col w-2/12">
                                                                  <RowInputGradingOption
                                                                    courseIndex={
                                                                      courseIndex
                                                                    }
                                                                    errors={
                                                                      errors
                                                                    }
                                                                    rowIndex={
                                                                      rowIndex
                                                                    }
                                                                    submitCount={
                                                                      submitCount
                                                                    }
                                                                    values={
                                                                      values
                                                                    }
                                                                  />
                                                                </div>
                                                              </>
                                                            );
                                                          default:
                                                            return (
                                                              <>
                                                                <div className="flex flex-col w-5/12">
                                                                  <RowInputCourseTitle
                                                                    courseIndex={
                                                                      courseIndex
                                                                    }
                                                                    errors={
                                                                      errors
                                                                    }
                                                                    rowIndex={
                                                                      rowIndex
                                                                    }
                                                                    submitCount={
                                                                      submitCount
                                                                    }
                                                                  />
                                                                </div>
                                                                <div className="pr-10 flex flex-col w-1/12">
                                                                  <RowInputGradingOption
                                                                    courseIndex={
                                                                      courseIndex
                                                                    }
                                                                    errors={
                                                                      errors
                                                                    }
                                                                    rowIndex={
                                                                      rowIndex
                                                                    }
                                                                    submitCount={
                                                                      submitCount
                                                                    }
                                                                    values={
                                                                      values
                                                                    }
                                                                  />
                                                                </div>

                                                                <div className="flex flex-col w-2/12">
                                                                  <RowInputProgramRequirement
                                                                    courseIndex={
                                                                      courseIndex
                                                                    }
                                                                    errors={
                                                                      errors
                                                                    }
                                                                    programRequirement={
                                                                      programRequirement
                                                                    }
                                                                    rowIndex={
                                                                      rowIndex
                                                                    }
                                                                    submitCount={
                                                                      submitCount
                                                                    }
                                                                    values={
                                                                      values
                                                                    }
                                                                  />
                                                                </div>
                                                              </>
                                                            );
                                                        }
                                                      })()}

                                                      {/* 12% here leaves room for the trash can */}
                                                      <div
                                                        className="flex flex-col w-2/12 pl-8"
                                                        style={{ width: "12%" }}
                                                      >
                                                        <RowInputSingularUnits
                                                          course={course}
                                                          courseIndex={
                                                            courseIndex
                                                          }
                                                          errors={errors}
                                                          rowIndex={rowIndex}
                                                          submitCount={
                                                            submitCount
                                                          }
                                                          values={values}
                                                          currentQuarterIndex={
                                                            currentQuarterIndex
                                                          }
                                                          courseAccessLastQuarterYearIndex={
                                                            courseAccessLastQuarterYearIndex
                                                          }
                                                        />
                                                      </div>
                                                      <div className="flex flex-grow justify-end items-center pr-8">
                                                        <RowInputRemoveButton
                                                          courseIndex={
                                                            courseIndex
                                                          }
                                                          rowIndex={rowIndex}
                                                          setFieldValue={
                                                            setFieldValue
                                                          }
                                                          setInitialValuesLog={
                                                            setInitialValuesLog
                                                          }
                                                          values={values}
                                                        />
                                                      </div>
                                                    </div>
                                                    {row.courses.length ===
                                                      courseIndex + 1 && (
                                                      <React.Fragment>
                                                        <AddCourse
                                                          rowIndex={rowIndex}
                                                          courseIndex={
                                                            courseIndex
                                                          }
                                                          values={values}
                                                          setFieldValue={
                                                            setFieldValue
                                                          }
                                                          setInitialValuesLog={
                                                            setInitialValuesLog
                                                          }
                                                        />
                                                        <QuarterTotalUnits
                                                          rowIndex={rowIndex}
                                                          row={row}
                                                        />

                                                        {/*<React.Fragment>
                                                            {values.rows.length ===
                                                            rowIndex + 1 && (
                                                            <AllTotalUnits
                                                            totalSum={totalSum}
                                                            values={values}
                                                            />
                                                            )}
                                                            </React.Fragment>A */}
                                                      </React.Fragment>
                                                    )}
                                                  </div>
                                                );
                                              },
                                            )}
                                            {row.courses.length === 0 && (
                                              <React.Fragment>
                                                <div>
                                                  <div className="flex flex-wrap items-center mt-2 border-t py-8 border-stone-400">
                                                    No courses are currently
                                                    listed
                                                  </div>
                                                  <AddCourse
                                                    rowIndex={rowIndex}
                                                    courseIndex={0}
                                                    values={values}
                                                    setFieldValue={
                                                      setFieldValue
                                                    }
                                                    setInitialValuesLog={
                                                      setInitialValuesLog
                                                    }
                                                  />
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                  <div className="mt-10">
                                    {/* <div className="year-quarter-column flex flex-col col-span-1 invisible ">
                                        <div className="pt-6 pl-6 font-bold">
                                        <h5>row["year"]</h5>
                                        </div>
                                        </div> */}

                                    <div className="mt-5">
                                      <AllTotalUnits totalSum={totalSum} />
                                    </div>
                                  </div>

                                  <div className="mt-20">
                                    <h2 className="type-2 mb-0">Review</h2>
                                  </div>
                                  <GridWithCheckBoxes values={values} />

                                  <div className="mt-16">
                                    <p>
                                      If you need to{" "}
                                      <a
                                        href="https://ed.stanford.edu/students/forms/masters"
                                        target="_blank"
                                        rel="nofollower"
                                      >
                                        submit a course petition for your
                                        program
                                      </a>
                                      , please visit the Master's Forms page.
                                    </p>
                                  </div>

                                  <div className="mt-20">
                                    <h2 className="type-2 mb-0">Attestation</h2>
                                  </div>
                                  <Attestation />

                                  <div className="mt-16">
                                    You will be able to preview your submission
                                    before your proposal is routed for approval.
                                  </div>
                                </div>
                              );
                            }}
                          </FieldArray>
                          <SubmitBasic
                            values={values}
                            isSubmitting={isSubmitting}
                            formRef={formRef}
                            onValidationInfoMessages={
                              handleValidationInfoMessages
                            }
                          />
                          <SubmitPDF
                            values={values}
                            formRef={formRef}
                            openModalPdf={openModalPdf}
                            setUrlPdfFileStorage={setUrlPdfFileStorage}
                            setPdfFileName={setPdfFileName}
                            onValidationErrorMessages={
                              handleValidationErrorMessages
                            }
                            onValidationInfoMessages={
                              handleValidationInfoMessages
                            }
                            smartsheetOverridesProgramLastQuarterRelevantIndividual={
                              smartsheetOverridesProgramLastQuarterRelevantIndividual
                            }
                          />
                          {messageListAlertErrors.length > 0 && (
                            <AlertErrorValidations
                              messageListAlertErrors={messageListAlertErrors}
                            />
                          )}
                          {messageListAlertInfo.length > 0 && (
                            <AlertInfoValidations
                              messageListAlertInfo={messageListAlertInfo}
                            />
                          )}

                          {/* https://dev.to/martinwachira/how-to-use-react-loader-spinner-library-3m89 */}
                          {loadingSmartsheet ? (
                            <SpinnerOverlay loading={loadingSmartsheet} />
                          ) : (
                            <div></div>
                          )}
                        </Form>
                        <AlertErrorBasic />
                      </React.Fragment>
                    );
                  }}
                </Formik>
              )}
              <SpinnerOverlay loading={pageLoading} />
              {!isFormIntakeVisible &&
                pageStatusMessage === "do not show form" && (
                  <NavigateToStatusPage />
                )}
            </article>
          ) : (
            <div>
              <p className="text-4xl mt-30">
                To begin your proposal, please log in below.
              </p>
              <p>
                <button
                  onClick={() => handleSignIn()}
                  className="button"
                  type="button"
                >
                  Stanford Login
                </button>
              </p>
            </div>
          )}
        </div>
      </div>
      <FooterStanford />
    </div>
  );
};
export default IntakeForm;
